.footer {
    height: 20vh;
    background-color:var(--light-primary-color);
    color:var(--primary-text-color);  
    font-weight: 700;
    font-size: 16px;
  }
  
  .footer-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  @media all and ( max-width: 640px) {
    .footer-top {
      flex-direction: column;
    }
  }
  
  .footer-bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }

  @media all and ( max-width: 640px) {
    .footer-bottom {
      width: 100vw;
      flex-direction: column;
    }
  }
  
  .footer-left {
    width: 50vw;
    justify-content: left;
    display: flex;
    flex-direction: column;
    padding: 2vw;
  }

  @media all and ( max-width: 640px) {
    .footer-left {
      width: 100vw;
      flex-direction: column;
      justify-content: center;
    }
  }
  
  .footer-right {
    width: 100vw;
    justify-content: right;
    display: flex;
    flex-direction: column;
    padding: 2vw;
  }

  @media all and ( max-width: 640px) {
    .footer-right {
      flex-direction: column;
      justify-content: center;
    }
  }
  
  .footer-link {
    text-align: left;
    padding-bottom: 4px;
    color: var(--primary-text-color);
  }

  @media all and ( max-width: 640px) {
    .footer-link {
      text-align: center;
    }
  }
  
  .footer-contact {
    text-align: right;
  }

  @media all and ( max-width: 640px) {
    .footer-contact {
      text-align: center;
    }
  }

  .copyright-box {
    text-align: center;
  }

.mini-logo {
  padding-top: 1vmin;
  height: 4vmin;
  pointer-events: none;
}

