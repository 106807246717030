.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .main-body {
    background-color:var(--default-primary-color);
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color:var(--text-primary-color);
    padding-left: 20px;
    padding-right: 20px;
  }

  .page-content {
    background-color:var(--default-primary-color);
    color:var(--text-primary-color);
    font-size: calc(4px + 2vmin);
    text-align: left;
    padding: 5px;
  }

  .text-callout {
    font-weight: bolder;
  }

  .toc-link {
    color:var(--text-primary-color)
  }

  .text-link {
    color:var(--secondary-text-color)
  }
