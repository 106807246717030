.menu {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 10vh;
    background-color:var(--light-primary-color);
    color:var(--primary-text-color);
  }
  
  .menu-item {
    font-size: calc(6px + 2vmin);
    font-weight: 900;
    text-align: center;
    color:var(--primary-text-color);
  }

  .home-logo {
    padding-top: 1vmin;
    height: 6vmin;
    pointer-events: none;
  }

  .menu-link {
    color: var(--primary-text-color);
  }
  