:root {
  --dark-primary-color: #34515e;
  --default-primary-color: #607D8B;
  --light-primary-color: #8eacbb;
  --text-primary-color: #FFFFFF;
  --accent-color: #448AFF;
  --primary-text-color: #212121;
  --secondary-text-color: #212121;
  --divider-color: #BDBDBD;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

